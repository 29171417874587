.trade {
  &__header {
    height: 150px;
    &Bg {
      left: 0;
      right: 0;
      bottom: 0;
      height: 300px;
      position: absolute;
      background-image: conic-gradient(
          from 90deg at 49% 50%,
          rgba(var(--ion-background-color-rgb), 0) 0deg,
          rgba(var(--ion-color-primary-rgb), 0.8) 360deg
        ),
        conic-gradient(
          from 270deg at 49% 50%,
          rgba(var(--ion-color-primary-rgb), 0.8) 0deg,
          rgba(var(--ion-background-color-rgb), 0) 360deg
        );
      background-repeat: no-repeat;
      background-size: 50% 100%;
      background-position: 0px 0px, 100% 100%;
    }
    &Content {
      top: 48px;
      z-index: 2;
      left: 30px;
      right: 30px;
      bottom: 14px;
      display: flex;
      position: absolute;
      flex-direction: column;
      &Button {
        --padding-end: 0px;
        --padding-start: 0px;
        margin: 0;
        width: max-content;
        height: max-content;
        svg {
          width: 24px;
          height: 24px;
        }
      }
      &Title {
        line-height: 31px;
        text-align: center;
        h1 {
          font-size: 24px;
          font-weight: 700;
        }
        &Container {
          display: flex;
          margin-top: 12px;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
  }
  &__content {
    --padding-top: 23px;
    --padding-bottom: 35px;
    --padding-end: var(--content-padding-horizontal);
    --padding-start: var(--content-padding-horizontal);
  }
  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__peer {
    gap: 10px;
    display: flex;
    align-items: center;
    &Avatar {
      width: 30px;
      height: 30px;
    }
    &Name {
      p {
        font-size: 15px;
        font-weight: 700;
        line-height: 20px;
      }
    }
    &Stats {
      p {
        font-size: 10px;
        font-weight: 500;
        line-height: 13px;
      }
    }
  }
  &__status {
    p {
      font-size: 24px;
      font-weight: 700;
    }
  }
  &__timer {
    gap: 4px;
    display: flex;
    margin-top: 21px;
    align-items: center;
    p {
      font-size: 14px;
      font-weight: 500;
      line-height: 13px;
    }
    &Message {
      p {
        margin-top: 10px;
        font-size: 14px;
        font-weight: 600;
        line-height: 13px;
      }
    }
  }
  &__acknowledgement {
    display: flex;
    margin-top: 35px;
    position: relative;
    align-items: center;
    padding-block: 19px;
    padding-inline: 35px;
    justify-content: center;
    border: 2px solid rgb(var(--ion-color-tertiary-rgb));
    &Text {
      p {
        font-size: 15px;
        text-align: center;
      }
    }
    &Stamp {
      gap: 4px;
      bottom: -9px;
      display: flex;
      position: absolute;
      align-items: center;
      padding-inline: 20px;
      background-color: rgb(var(--ion-background-color-rgb));
      justify-content: space-between;
      svg {
        width: 12px;
        height: 12px;
      }
      p {
        font-size: 12px;
        font-weight: 700;
      }
    }
  }
  &__chatButton {
    --padding-end: 4px;
    --padding-start: 4px;
    margin: 0;
    width: max-content;
    height: max-content;
    svg {
      width: 20px;
      height: 20px;
    }
  }
  &__timeline {
    margin-top: 47px;
    &Item {
      gap: 13px;
      display: flex;
      align-items: center;
    }
    &Dot {
      position: relative;
      color: rgb(var(--ion-color-tertiary-rgb));
      svg {
        width: 10px;
        height: 10px;
      }
      & > span {
        display: none;
      }
      &--done {
        color: rgb(var(--ion-color-primary-rgb));
      }
      &--active {
        color: rgb(var(--ion-color-primary-rgb));
        & > span {
          top: 6px;
          width: 10px;
          height: 10px;
          display: block;
          position: absolute;
          border-radius: 50%;
          background-color: rgb(var(--ion-color-primary-rgb));
          animation: puffOutCenter 1s linear 0s infinite normal none;
        }
      }
      &--payment_made {
        & > span {
          display: none;
        }
      }
    }
    &Text {
      p {
        font-size: 15px;
        font-weight: 500;
      }
    }
    &Spacer {
      height: 30px;
      margin-left: 4px;
      border: 1px solid rgba(var(--ion-color-tertiary-rgb), 0.4);
    }
  }
  &__payment {
    gap: 12px;
    display: flex;
    margin-top: 27px;
    flex-direction: column;
    &Message {
      p {
        font-size: 15px;
        font-weight: 500;
      }
      &--paid {
        p {
          margin-top: 10px;
        }
      }
    }
    &Method {
      p {
        font-size: 24px;
        font-weight: 500;
      }
    }
    &Details {
      display: flex;
      flex-direction: column;
      h6 {
        font-size: 10px;
        font-weight: 700;
        letter-spacing: 1px;
        text-transform: uppercase;
      }
      p {
        gap: 4px;
        display: flex;
        font-size: 15px;
        font-weight: 500;
        align-items: center;
        letter-spacing: -0.9px;
        ion-button {
          --padding-end: 1px;
          --padding-start: 1px;
          margin: 0;
          height: max-content;
          svg {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
  &__footer {
    ion-toolbar {
      --padding-end: var(--content-padding-horizontal);
      --padding-start: var(--content-padding-horizontal);
      --background: var(--ion-background-color);
      &:first-of-type {
        --border-width: 0px;
      }
    }
    &.footer-toolbar-padding {
      ion-toolbar {
        &:last-of-type {
          padding-bottom: calc(var(--ion-safe-area-bottom) + 20px);
        }
      }
    }
  }
  &__actions {
    gap: 29px;
    display: grid;
    justify-content: space-between;
    grid-template-columns: 124px 1fr;
    ion-button {
      margin: 0;
    }
    &--full {
      grid-template-columns: 1fr;
    }
  }
  &__skeleton {
    display: flex;
    align-items: center;
    gap: 20px;
    --padding-end: var(--content-padding-horizontal);
    --padding-start: var(--content-padding-horizontal);
    &::part(native) {
      --inner-padding-end: 0px;
    }
    &Avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      align-self: flex-start;
    }
    ion-label {
      flex-grow: 1;
    }

    &--last {
      margin-top: 10px;
      ion-label {
        ion-skeleton-text {
          height: 30px;
          margin-bottom: 15px;
        }
      }
    }
  }
  &__completedText {
    margin-top: 20px;
    p {
      font-size: 15px;
      font-weight: 500;
      line-height: 20px;
    }
  }
}

@keyframes puffOutCenter {
  0% {
    transform: scale(1);
    filter: blur(0);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    filter: blur(4px);
    opacity: 0;
  }
}
