.listings {
  &__header {
    height: 228px;
    padding-block: 68px 14px;
    padding-inline: var(--content-padding-horizontal);
    &Bg {
      left: 0;
      right: 0;
      bottom: 0;
      height: 456px;
      position: absolute;
      background: conic-gradient(
        from 270deg at 50% 50%,
        rgba(var(--ion-color-primary-rgb), 0.8),
        rgba(var(--ion-background-color-rgb), 0)
      );
    }
    & > .header-background {
      background: transparent;
      backdrop-filter: saturate(180%) blur(12px);
    }
    &Content {
      top: 70px;
      z-index: 2;
      left: 30px;
      right: 30px;
      position: absolute;
    }
    &Top {
      display: flex;
      margin-bottom: 32px;
      align-items: center;
      justify-content: space-between;
    }
  }
  &__types {
    margin-bottom: 20px;
    ion-segment {
      --background: transparent;
      border-radius: 0;
      border: 2px solid var(--ion-text-color);
      &-button {
        --border-radius: 0;
        --color: var(--ion-text-color);
        --color-checked: var(--ion-background-color);
        --background: transparent;
        --indicator-color: var(--ion-text-color);
        height: 40px;
        margin-top: 0;
        font-size: 15px;
        margin-bottom: 0;
        font-weight: 700;
        &::before {
          border-left: 0;
        }
        &::part(indicator) {
          padding-inline: 0px;
        }
      }
    }
  }
  &__categoryFilter {
    margin: 0;
    height: max-content;
    font-weight: 700;
    &::part(native) {
      --padding-end: 4px;
      --padding-start: 4px;
    }
  }
  &__selectedCategory {
    gap: 4px;
    display: flex;
    align-items: center;
    font-size: 15px;
  }
  &__content {
    --padding-bottom: 104px;
  }
  &__title {
    display: flex;
    ion-text {
      color: var(--ion-text-color);
      h1 {
        font-size: 24px;
        font-weight: 700;
      }
    }
  }
  &__menu {
    &Btn {
      margin: 0;
      height: max-content;
      --color: var(--ion-text-color);
      &::part(native) {
        padding: 0;
      }
      svg {
        width: 3px;
        height: 16px;
      }
    }
  }
  &__currency {
    p {
      font-size: 16px;
      font-weight: 600;
      // color: var(--ion-text-color);
    }
  }
  &__filters {
    gap: 18px;
    display: flex;
    align-items: center;
    --color: var(--ion-text-color);
    ion-input,
    ion-select {
      letter-spacing: 0.1em;
      --color: var(--ion-text-color);
    }
    &Input {
      gap: 10px;
      display: flex;
      align-items: center;
      ion-input {
        --placeholder-opacity: 1;
        &[class*='sc-ion-input'] {
          --padding-end: 0px;
          --padding-top: 0px;
          --padding-start: 0px;
          --padding-bottom: 0px;
        }
        width: 64px;
        font-size: 10px;
        font-weight: 700;
        height: max-content;
        input {
          color: red;
        }
      }
      ion-button {
        --padding-end: 0px;
        --padding-start: 0px;
        --color: var(--ion-text-color);
        margin: 0;
        height: max-content;
        svg {
          width: 10px;
          height: 10px;
        }
      }
    }
    ion-select {
      --padding-end: 0px;
      --padding-top: 0px;
      --padding-start: 0px;
      --padding-bottom: 0px;
      --placeholder-opacity: 1;
      color: var(--color);
      font-weight: 700;
      font-size: 10px;
      line-height: 13px;
      text-transform: uppercase;
      &::after {
        right: 4px;
        content: '>';
        font-size: 15px;
        position: absolute;
        transform: rotate(90deg);
      }
      &::part(icon) {
        margin-inline: 0;
        visibility: hidden;
      }
    }
  }
  &__list {
    margin-bottom: 52px;
  }
  &__skeleton {
    padding-top: 20px;
    --padding-end: var(--content-padding-horizontal);
    --padding-start: var(--content-padding-horizontal);
    &::part(native) {
      --inner-padding-end: 0px;
    }
    &Avatar {
      width: 40px;
      height: 40px;
      align-self: flex-start;
      border-radius: 50%;
    }
    ion-label {
      margin-top: 0;
      ion-skeleton-text {
        margin-bottom: 15px;
      }
    }
  }
  ion-fab {
    bottom: 108px;
    &-button {
      --transform: none;
      --border-radius: 50px;
      margin: 0;
      width: max-content;
      &::part(native) {
        width: 132px;
      }
      svg {
        width: 15px;
        height: 15px;
      }
      span {
        font-size: 14px;
        margin-left: 9px;
        font-weight: 700;
        line-height: 18px;
      }
    }
  }
  &__empty {
    ion-label {
      p {
        display: flex;
        align-items: center;
      }
    }
  }
}
