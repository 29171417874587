.avatar {
  color: #bbb;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  &--noImg {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
