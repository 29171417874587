.postListing {
  &__header {
    height: 150px;
    &Bg {
      left: 0;
      right: 0;
      bottom: 0;
      height: 300px;
      position: absolute;
      background-image: conic-gradient(
          from 90deg at 49% 50%,
          rgba(var(--ion-background-color-rgb), 0) 0deg,
          rgba(var(--ion-color-primary-rgb), 0.8) 360deg
        ),
        conic-gradient(
          from 270deg at 49% 50%,
          rgba(var(--ion-color-primary-rgb), 0.8) 0deg,
          rgba(var(--ion-background-color-rgb), 0) 360deg
        );
      background-repeat: no-repeat;
      background-size: 50% 100%;
      background-position: 0px 0px, 100% 100%;
    }
    &Content {
      top: 48px;
      z-index: 2;
      left: 30px;
      right: 30px;
      bottom: 14px;
      display: flex;
      position: absolute;
      flex-direction: column;
      &Button {
        --padding-end: 0px;
        --padding-start: 0px;
        margin: 0;
        width: max-content;
        height: max-content;
        svg {
          width: 24px;
          height: 24px;
        }
      }
      &Title {
        font-size: 24px;
        margin-top: 12px;
        line-height: 31px;
        text-align: center;
        h1 {
          font-weight: 700;
        }
      }
    }
  }
  &__content {
    --padding-end: var(--content-padding-horizontal);
    --padding-start: var(--content-padding-horizontal);
    --padding-top: 29px;
    position: relative;
  }
  &__currencies {
    gap: 9px;
    display: flex;
    flex-direction: column;
  }
  &__currency {
    display: flex;
    align-items: center;
    &--holding {
      gap: 7px;
    }
    &--trade {
      gap: 9px;
    }
    h2 {
      font-size: 24px;
      font-weight: 700;
      line-height: 31px;
    }
    ion-select {
      text-transform: uppercase;
      &::part(text) {
        font-size: 24px;
      }
      &::after {
        font-size: 24px;
      }
    }
  }
  &__rate {
    display: flex;
    margin-top: 30px;
    flex-direction: column;
    &Label {
      line-height: 13px;
      text-transform: uppercase;
      h3 {
        font-size: 10px;
        font-weight: 700;
      }
    }
    &Input {
      height: 48px;
      display: flex;
      font-size: 40px;
      font-weight: 700;
      align-items: center;
      .rate {
        ion-input {
          border: none;
          input[class*='sc-ion-input'] {
            --placeholder-font-weight: 700;
            &.native-input {
              flex: unset;
              padding: 0;
              width: 150px;
              font-size: 40px;
              font-weight: 800;
              align-self: center;
              border: none;
              &::placeholder {
                font-size: inherit;
              }
            }
          }
        }
      }
    }
    &Currency {
      &--trade {
        span {
          font-size: 14px;
        }
      }
    }
    &Info {
      span {
        font-size: 10px;
        color: #a40000;
        font-weight: 700;
        line-height: 13px;
      }
    }
  }
  &__limits {
    gap: 9px;
    display: flex;
    margin-top: 30px;
    flex-direction: column;
    &Label {
      line-height: 13px;
      text-transform: uppercase;
      h3 {
        font-size: 10px;
        font-weight: 700;
      }
    }
    &Input {
      height: 50px;
      display: flex;
      font-size: 15px;
      font-weight: 500;
      align-items: center;
      padding-inline: 0 17px;
      justify-content: space-between;
      border: 2px solid var(--ion-color-dark);

      .limit {
        --padding-end: 0px;
        --padding-top: 0px;
        --padding-bottom: 0px;
        flex: unset;
        width: max-content;
        ion-text {
          top: 9px;
          left: 7px;
        }
        &--min {
          --padding-start: 0px;
        }
        ion-input {
          border: none;
          input[class*='sc-ion-input'] {
            &.native-input {
              flex: unset;
              width: 100px;
              padding: 0;
            }
          }
        }
      }
    }
  }
  &__paymentMethods {
    margin-top: 46px;
    &Header {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      justify-content: space-between;
      ion-button {
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
      }
    }
    &Title {
      h3 {
        font-size: 10px;
        font-weight: 700;
        line-height: 13px;
        text-transform: uppercase;
      }
    }
    &Info {
      p {
        font-size: 15px;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 14px;
      }
    }
    &List {
      gap: 10px;
      display: flex;
      margin-bottom: 22px;
      flex-direction: column;
      ion-checkbox {
        --size: 20px;
        --border-radius: 0px;
        font-size: 12px;
        font-weight: 500;
      }
    }
    &Loader {
      gap: 10px;
      display: flex;
      margin-bottom: 20px;
      flex-direction: column;
      ion-skeleton-text {
        margin: 0;
      }
    }
    &Empty {
      font-size: 12px;
      font-style: italic;
      margin-bottom: 10px;
    }
  }
  &__timeLimit {
    display: flex;
    margin-bottom: 30px;
    flex-direction: column;
    &Label {
      line-height: 13px;
      text-transform: uppercase;
      h3 {
        font-size: 10px;
        font-weight: 700;
        margin-bottom: 11px;
      }
    }
    ion-select {
      width: max-content;
      &::part(text) {
        font-size: 15px;
      }
    }
  }
  &__footer {
    ion-toolbar {
      --padding-end: var(--content-padding-horizontal);
      --padding-start: var(--content-padding-horizontal);
      --background: var(--ion-background-color);
      &:first-of-type {
        --border-width: 0px;
      }
    }
    &.footer-toolbar-padding {
      ion-toolbar {
        &:last-of-type {
          padding-bottom: calc(var(--ion-safe-area-bottom) + 20px);
        }
      }
    }
  }
  ion-select {
    --padding-end: 0px;
    --padding-top: 0px;
    --padding-start: 0px;
    --padding-bottom: 0px;
    --placeholder-opacity: 1;
    color: var(--ion-color-primary);
    font-weight: 700;
    line-height: 31px;
    &::after {
      right: 0;
      content: '>';
      position: absolute;
      transform: rotate(90deg);
    }
    &::part(icon) {
      width: 18px;
      visibility: hidden;
    }
  }
  .amountInput {
    ion-input {
      &[type='text'] {
        &[class*='sc-ion-input'] {
          --padding-start: 1px;
        }
      }
    }
  }
}
