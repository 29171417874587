.transactionItem {
  padding-top: 20px;
  --padding-end: var(--content-padding-horizontal);
  --padding-start: var(--content-padding-horizontal);
  &::part(native) {
    --inner-padding-end: 0px;
  }
  ion-label {
    margin: 0;
  }
  ion-avatar {
    margin: 0 14px 0 0;
    align-self: flex-start;
  }
  &__header,
  &__content,
  &__footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__header {
    line-height: 13px;
    span {
      font-size: 10px;
      font-weight: 700;
      line-height: 13px;
    }
  }
  &__content {
    margin-block: 3px 6px;
  }
  &__title {
    font-size: 16px;
    font-weight: 700;
    line-height: 21px;
    max-width: 160px;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
  }
  &__amount {
    font-size: 16px;
    font-weight: 700;
    text-align: right;
    line-height: 21px;
  }
  &__footer {
    margin-bottom: 21px;
  }
  &__time {
    display: flex;
    font-size: 11px;
    line-height: 14px;
  }
}
