.password {
  &::part(native) {
    position: relative;
  }
  ion-button {
    --padding-end: 0px;
    --padding-start: 0px;
    top: 34px;
    right: 8px;
    z-index: 5;
    position: absolute;
  }
}
