.tradeItem {
  padding-top: 20px;
  --padding-end: var(--content-padding-horizontal);
  --padding-start: var(--content-padding-horizontal);
  &::part(native) {
    --inner-padding-end: 0px;
  }
  ion-label {
    margin: 0;
  }
  ion-avatar {
    margin: 0 10px 0 0;
    align-self: flex-start;
  }
  &__header,
  &__content,
  &__footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__currency {
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
  }
  &__status {
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
  }
  &__content {
    margin-block: 15px 20px;
  }
  &__rate {
    gap: 5px;
    display: flex;
    flex-direction: column;
    span {
      font-size: 12px;
      line-height: 16px;
      &:first-child {
        font-weight: 700;
      }
      &:last-child {
        font-weight: 400;
      }
    }
  }
  &__amount {
    font-size: 24px;
    font-weight: 700;
    line-height: 31px;
  }
  &__footer {
    margin-bottom: 20px;
  }
  &__chat {
    font-size: 11px;
    &::part(native) {
    }
    span {
      font-weight: 400;
      margin-left: 6px;
    }
    svg {
      width: 14px;
      height: 14px;
    }
  }
  &__time {
    font-size: 11px;
    font-weight: 400;
    line-height: 14px;
  }
}
