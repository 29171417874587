.register {
  max-width: 768px;
  margin-inline: auto;
  &__logo {
    width: 30px;
    height: 42.16px;
  }
  &__header {
    display: flex;
    margin-top: 55px;
    flex-direction: column;
    h2 {
      font-weight: 700;
      line-height: 31px;
    }
  }
  &__form {
    --ion-grid-column-padding: 8px;
    margin-top: 61px;
    ion-item {
      ion-label {
        &.label-stacked {
          font-weight: 500;
          font-size: 15px;
          line-height: 20px;
        }
      }
    }
    &Submit {
      margin-top: 48px;
      ion-spinner {
        margin-left: 10px;
      }
    }
  }
  &__login {
    display: block;
    font-size: 12px;
    margin-top: 12px;
    font-weight: 700;
    line-height: 13px;
    text-align: center;
  }
}
