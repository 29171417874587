.amountInput {
  --min-height: 36px;
  ion-input {
    display: flex;
    align-items: center;

    ion-text {
      margin-left: 10px;
    }
    &[type='text'] {
      &[class*='sc-ion-input'] {
        min-height: var(--min-height);
        input {
          &::placeholder {
            font-weight: 500;
            font-size: 15px;
          }
        }
      }
    }
  }
}
