.transaction {
  &__header {
    margin-top: 50px;
    padding-left: 8px;
    padding-bottom: 35px;
    border-bottom: 1px solid #eeeeee3b;
    background: var(--ion-background-color);
    &Content {
      &Button {
        width: max-content;
        height: max-content;
        svg {
          width: 24px;
          height: 24px;
        }
      }

      &Info {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        .transaction__skeleton {
          display: flex;
          flex-direction: column;
          align-items: center;
          &Avatar {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            margin-bottom: 20px;
          }

          &Name {
            width: 100px;
            margin-top: 10px;
            height: 10px;
          }

          &Amount {
            width: 100px;
            height: 10px;
          }

          ion-label {
            margin-top: 0;
            ion-skeleton-text {
              margin-bottom: 5px;
            }
          }
        }

        ion-avatar {
          width: 80px;
          height: 80px;
          margin-bottom: 10px;
        }
      }

      &Amount {
        p {
          font-weight: 700;
        }
      }

      &Name {
        p {
          font-size: 14px;
        }
      }
    }
  }

  &__content {
    --padding-bottom: 20px;
    --padding-start: var(--content-padding-horizontal);
    --padding-end: var(--content-padding-horizontal);
    ion-list {
      display: flex;
      padding-block: 20px;
      justify-content: space-between;

      p {
        font-size: 14px;
        font-weight: 600;
        max-width: 50%;
      }

      p:last-of-type {
        display: flex;
        align-items: center;
        span {
          max-width: 200px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        ion-button {
          --padding-end: 0px;
          margin: 0;
          height: max-content;
          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    &Memo {
      margin-top: 50px;
      ion-text {
        h4 {
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 20px;
        }

        p {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }

  &__footer {
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline: var(--content-padding-horizontal);
    ion-button {
      margin: 0;
      &::part(native) {
        border-width: 1px;
      }
    }
  }
}
