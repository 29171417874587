* {
  letter-spacing: -0.06em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
}

ion-header {
  div[class$='_headerContent'] {
    max-width: 760px;
    margin-inline: auto;
  }
}

ion-content {
  &.ion-padding-horizontal {
    --padding-start: var(--content-padding-horizontal);
    --padding-end: var(--content-padding-horizontal);
  }
  &.ion-padding-top {
    --padding-top: 40px;
  }
  &::part(scroll) {
    max-width: 760px;
    margin-inline: auto;
  }
  ion-fab {
    @media screen and (min-width: 1200px) {
      right: 25%;
    }
  }
}

ion-footer {
  @media screen and (min-width: 1000px) {
    ion-button {
      &:only-child {
        width: 760px;
        margin-inline: auto;
      }
    }
  }
}

ion-col {
  padding-top: 0;
  padding-bottom: 0;
  &:first-child {
    padding-inline-start: 0;
  }
  &:last-child {
    padding-inline-end: 0;
  }
}

form {
  ion-row {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
  ion-item {
    --padding-start: 0px;
    --inner-padding-end: 0px;
    --inner-border-width: 0px;
    --ion-item-background: transparent;
    &.item.item-input {
      ion-input {
        --padding-start: 10px;
      }
    }
    ion-label {
      &.label-stacked {
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        margin: 0 0 8px 0;
      }
    }
    ion-input {
      &[class*='sc-ion-input'] {
        --placeholder-font-weight: 500;
      }
      border: 2px solid;
    }
    ion-select {
      --padding-end: 16px;
      --padding-start: 16px;
      border: 2px solid;
      &::after {
        right: 16px;
        content: '>';
        font-size: 15px;
        font-weight: 700;
        position: absolute;
        transform: rotate(90deg);
      }
      &::part(icon) {
        display: none;
      }
      &::part(placeholder) {
        font-weight: 500;
      }
    }
  }
  ion-button {
    margin: 0;
  }
}

ion-button {
  &.link {
    margin: 0;
    font-weight: 700;
    height: max-content;
    text-decoration-line: underline;
    &::part(native) {
      --padding-end: 4px;
      --padding-start: 4px;
      // background: transparent;
      // color: var(--ion-color-primary);
    }
  }
}

ion-toast {
  --border-radius: 0px;
}

.app {
  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

ion-popover {
  --width: unset;
  --min-width: 100px;
  @media (prefers-color-scheme: dark) {
    --backdrop-opacity: 0.5;
    --background: var(--ion-color-light-shade);
    ion-item {
      --background: var(--ion-color-light-shade);
    }
  }
}

ion-modal {
  &.modal-default:not(.modal-fullscreen) {
    @media screen and (min-width: 768px) {
      --width: 500px;
      --height: max-content;
      --border-radius: 0px;
      align-items: center;
    }
  }
}
