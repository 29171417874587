.transactions {
  &__header {
    height: 168px;
    padding-block: 68px 14px;
    padding-inline: var(--content-padding-horizontal);
    &Bg {
      left: 0;
      right: 0;
      bottom: 0;
      height: 456px;
      position: absolute;
      background: conic-gradient(
        from 270deg at 50% 50%,
        rgba(var(--ion-color-primary-rgb), 0.8),
        rgba(var(--ion-background-color-rgb), 0)
      );
    }
    & > .header-background {
      background: transparent;
      backdrop-filter: saturate(180%) blur(12px);
    }
    &Content {
      top: 68px;
      z-index: 2;
      left: 30px;
      right: 30px;
      position: absolute;
    }
    &Top {
      display: flex;
      align-items: center;
      margin-bottom: 32px;
      justify-content: space-between;
    }
  }
  &__title {
    display: flex;
    ion-text {
      color: var(--ion-text-color);
      h1 {
        font-size: 24px;
        font-weight: 700;
      }
    }
  }
  &__types {
    margin-bottom: 20px;
    ion-segment {
      --background: transparent;
      border-radius: 0;
      border: 2px solid var(--ion-text-color);
      &-button {
        --border-radius: 0;
        --color: var(--ion-text-color);
        --color-checked: var(--ion-background-color);
        --background: transparent;
        --indicator-color: var(--ion-text-color);
        height: 40px;
        margin-top: 0;
        font-size: 15px;
        margin-bottom: 0;
        font-weight: 700;
        &::before {
          border-left: 0;
        }
        &::part(indicator) {
          padding-inline: 0px;
        }
      }
    }
  }
  &__content {
    --padding-bottom: 104px;
  }
  &__skeleton {
    padding-top: 20px;
    --padding-end: var(--content-padding-horizontal);
    --padding-start: var(--content-padding-horizontal);
    &::part(native) {
      --inner-padding-end: 0px;
    }
    &Avatar {
      width: 40px;
      height: 40px;
      align-self: flex-start;
      border-radius: 50%;
    }
    ion-label {
      margin-top: 0;
      ion-skeleton-text {
        margin-bottom: 15px;
      }
    }
  }
  &__filters {
    gap: 9px;
    display: flex;
  }
  &__filter {
    margin: 0;
    height: 18px;
    font-size: 10px;
    font-weight: 700;
    line-height: 13px;
    padding-block: 2px;
    padding-inline: 6px;
    --background: transparent;
    &--active {
      --background: rgba(var(--ion-text-color-rgb), 0.8);
      --color: var(--ion-background-color);
    }
  }
}
