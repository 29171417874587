.acceptRequest {
  --width: 100%;
  --height: max-content;
  --border-radius: 0px;
  align-items: flex-end;
  &::part(content) {
    padding-block: 35px 43px;
    padding-inline: var(--content-padding-horizontal);
  }
  &__header {
    display: flex;
    flex-direction: column;
  }
  &__title {
    font-size: 24px;
    line-height: 31px;
    margin-block: 23px 20px;
    h4 {
      font-weight: 700;
    }
  }
  &__form {
    display: flex;
    flex-direction: column;
  }
  &__close {
    --padding-end: 0px;
    --padding-start: 0px;
    margin: 0;
    width: max-content;
    height: max-content;
    svg {
      width: 20px;
      height: 20px;
    }
  }
  &__text {
    font-weight: 700;
    letter-spacing: -0.9px;
  }
  &__actions {
    gap: 28px;
    display: grid;
    margin-top: 30px;
    grid-template-columns: 124px 1fr;
  }
}
