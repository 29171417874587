.validate {
  max-width: 768px;
  margin-inline: auto;
  &__header {
    display: flex;
    margin-top: 66.7px;
    text-align: center;
    flex-direction: column;
    h2 {
      font-weight: 700;
      line-height: 31px;
      margin-bottom: 9px;
    }
  }
  &__form {
    margin-top: 43px;
    ion-col {
      --ion-grid-column-padding: 11px;
      flex-grow: 0;
      height: 50px;
      ion-item {
        width: 50px;
        height: 50px;
        &::part(native) {
          height: 100%;
        }
        ion-input {
          --placeholder-font-weight: 700;
          font-size: 24px;
          font-weight: 700;
          &.legacy-input {
            --padding-top: 0px;
            --padding-end: 0px;
            --padding-bottom: 0px;
            text-align: center;
            height: 100%;
          }
        }
      }
    }
    ion-button {
      max-width: 450px;
      margin-top: 48px;
      margin-inline: auto;
      ion-spinner {
        margin-left: 10px;
      }
    }
  }
}
