.recentListings {
  &__header {
    display: flex;
    padding-top: 21px;
    align-items: center;
    justify-content: space-between;
    padding-inline: var(--content-padding-horizontal);
    ion-label {
      margin: 0;
      font-size: 20px;
      font-weight: 700;
      line-height: 26px;
    }
    .link {
      font-size: 12px;
    }
  }
  &--loading {
    margin-top: 20px;
  }
  &__skeleton {
    --min-height: 20px;
    --padding-end: var(--content-padding-horizontal);
    --padding-start: var(--content-padding-horizontal);
    ion-skeleton-text {
      margin: 0;
    }
  }
}
