.withdraw {
  &__header {
    height: 125px;
    &Bg {
      left: 0;
      right: 0;
      bottom: 0;
      height: 281px;
      position: absolute;
      background-image: conic-gradient(
          from 90deg at 49% 50%,
          rgba(var(--ion-background-color-rgb), 0) 0deg,
          rgba(var(--ion-color-primary-rgb), 0.8) 360deg
        ),
        conic-gradient(
          from 270deg at 49% 50%,
          rgba(var(--ion-color-primary-rgb), 0.8) 0deg,
          rgba(var(--ion-background-color-rgb), 0) 360deg
        );
      background-repeat: no-repeat;
      background-size: 50% 100%;
      background-position: 0px 0px, 100% 100%;
    }
    &Content {
      top: 40px;
      z-index: 2;
      left: 30px;
      right: 30px;
      bottom: 14px;
      display: flex;
      position: absolute;
      flex-direction: column;
      &Button {
        --padding-end: 0px;
        --padding-start: 0px;
        margin: 0;
        width: max-content;
        height: max-content;
        svg {
          width: 20px;
          height: 20px;
        }
      }
      &Title {
        font-size: 24px;
        margin-top: 12px;
        line-height: 31px;
        text-align: center;
        h1 {
          font-weight: 700;
        }
      }
    }
  }
  &__content {
    --padding-top: 58px;
    --padding-end: var(--content-padding-horizontal);
    --padding-start: var(--content-padding-horizontal);
  }
  &__form {
    ion-grid {
      display: flex;
      flex-direction: column;
      ion-row {
        margin-bottom: 32px;
        &:last-child {
          margin-top: 40px;
        }
      }
    }
    &Select {
      &Label {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
  &__info {
    p {
      margin-top: 8px;
    }
  }
}
