.createTrade {
  &__header {
    height: 150px;
    &Bg {
      left: 0;
      right: 0;
      bottom: 0;
      height: 300px;
      position: absolute;
      background-image: conic-gradient(
          from 90deg at 49% 50%,
          rgba(var(--ion-background-color-rgb), 0) 0deg,
          rgba(var(--ion-color-primary-rgb), 0.8) 360deg
        ),
        conic-gradient(
          from 270deg at 49% 50%,
          rgba(var(--ion-color-primary-rgb), 0.8) 0deg,
          rgba(var(--ion-background-color-rgb), 0) 360deg
        );
      background-repeat: no-repeat;
      background-size: 50% 100%;
      background-position: 0px 0px, 100% 100%;
    }
    &Content {
      top: 48px;
      z-index: 2;
      left: 30px;
      right: 30px;
      bottom: 14px;
      display: flex;
      position: absolute;
      flex-direction: column;
      &Button {
        --padding-end: 5px;
        --padding-start: 5px;
        margin: 0;
        width: max-content;
        height: max-content;
        svg {
          width: 24px;
          height: 24px;
        }
      }
      &Title {
        margin-top: 12px;
        line-height: 31px;
        text-align: center;
        h1 {
          font-size: 24px;
          font-weight: 700;
        }
      }
    }
  }
  &__content {
    --padding-end: var(--content-padding-horizontal);
    --padding-start: var(--content-padding-horizontal);
    --padding-top: 23px;
  }
  &__merchant {
    gap: 10px;
    display: flex;
    align-items: center;
    &Avatar {
      width: 30px;
      height: 30px;
    }
    &Name {
      p {
        font-size: 15px;
        font-weight: 700;
        line-height: 20px;
      }
    }
    &Stats {
      p {
        font-size: 10px;
        font-weight: 500;
        line-height: 13px;
      }
    }
  }
  &__info {
    display: flex;
    margin-top: 20px;
    flex-direction: column;
  }
  &__rate {
    p {
      font-size: 24px;
      font-weight: 700;
      line-height: 31px;
      span {
        font-size: 14px;
      }
    }
  }
  &__limit {
    p {
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
      span {
        font-weight: 500;
      }
    }
  }
  &__form {
    margin-top: 25px;
  }
  &__submit {
    margin-top: 21px;
    &Text {
      gap: 10px;
      display: flex;
      align-items: center;
    }
  }
  &__calculation {
    margin-top: 29px;
    &Text {
      &:first-child {
        p {
          margin-bottom: 7px;
        }
      }
      p {
        font-size: 15px;
        font-weight: 500;
        line-height: 20px;
      }
    }
    &Row {
      gap: 16px;
      display: flex;
      align-items: center;
      margin-bottom: 16px;
    }
    &Sign {
      p {
        font-size: 24px;
        font-weight: 700;
        line-height: 31px;
      }
    }
  }
  &__result {
    &Title {
      p {
        font-size: 15px;
        font-weight: 500;
        line-height: 20px;
      }
    }
    &Amount {
      p {
        font-size: 32px;
        font-weight: 700;
        line-height: 42px;
      }
    }
  }
  &__skeleton {
    margin-left: -15px;
    &Avatar {
      width: 40px;
      height: 40px;
      align-self: flex-start;
      border-radius: 50%;
    }
    &--last {
      margin-top: 10px;
      margin-left: -15px;
    }
    ion-label {
      margin-top: 0;
      --border-bottom: none;
      ion-skeleton-text {
        margin-bottom: 10px;
      }
    }
  }
}
