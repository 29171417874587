.addPaymentMethod {
  --width: 100%;
  --height: max-content;
  --border-radius: 0px;
  align-items: flex-end;
  &::part(content) {
    padding-block: 35px 43px;
    padding-inline: var(--content-padding-horizontal);
  }
  &__header {
    display: flex;
    flex-direction: column;
  }
  &__title {
    font-size: 24px;
    line-height: 31px;
    margin-block: 24px 43px;
    h4 {
      font-weight: 700;
    }
  }
  &__close {
    --padding-end: 0px;
    --padding-start: 0px;
    margin: 0;
    width: max-content;
    height: max-content;
    svg {
      width: 20px;
      height: 20px;
    }
  }
  &__form {
    ion-grid {
      display: flex;
      flex-direction: column;
      ion-row {
        margin-bottom: 22px;
        &:last-child {
          margin-top: 20px;
        }
      }
    }
  }
  &__submit {
    ion-spinner {
      margin-left: 10px;
    }
  }
}
