.requestNegotiations {
  &__header {
    height: 150px;
    &Bg {
      left: 0;
      right: 0;
      bottom: 0;
      height: 300px;
      position: absolute;
      background-image: conic-gradient(
          from 90deg at 49% 50%,
          rgba(var(--ion-background-color-rgb), 0) 0deg,
          rgba(var(--ion-color-primary-rgb), 0.8) 360deg
        ),
        conic-gradient(
          from 270deg at 49% 50%,
          rgba(var(--ion-color-primary-rgb), 0.8) 0deg,
          rgba(var(--ion-background-color-rgb), 0) 360deg
        );
      background-repeat: no-repeat;
      background-size: 50% 100%;
      background-position: 0px 0px, 100% 100%;
    }
    &Content {
      top: 48px;
      z-index: 2;
      left: 30px;
      right: 30px;
      bottom: 14px;
      display: flex;
      position: absolute;
      flex-direction: column;
      &Button {
        --padding-end: 5px;
        --padding-start: 5px;
        margin: 0;
        width: max-content;
        height: max-content;
        svg {
          width: 24px;
          height: 24px;
        }
      }
      &Title {
        margin-top: 12px;
        line-height: 31px;
        text-align: center;
        h1 {
          font-size: 24px;
          font-weight: 700;
        }
      }
    }
  }
  &__item {
    --padding-start: 0px;
    --inner-padding-end: var(--content-padding-horizontal);
    --inner-padding-start: var(--content-padding-horizontal);
    --inner-padding-top: 15px;
    --inner-padding-bottom: 5px;
    align-items: flex-start;
    &Avatar {
      width: 30px;
      height: 30px;
      margin-right: 20px;
    }
    ion-label {
      display: flex;
      flex-direction: column;
      margin-top: 0;
      h4 {
        font-size: 15px;
        font-weight: 700;
        line-height: 20px;
      }
      p {
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
      }
    }
  }
  &__skeleton {
    padding-top: 20px;
    --padding-end: 0;
    --padding-start: 0;
    &::part(native) {
      --inner-padding-end: 0px;
    }
    &Avatar {
      width: 40px;
      height: 40px;
      align-self: flex-start;
      border-radius: 50%;
    }
    ion-label {
      margin-top: 0;
      ion-skeleton-text {
        margin-bottom: 5px;
      }
    }
  }
}
