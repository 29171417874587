.kyc {
  max-width: 768px;
  margin-inline: auto;
  &__header {
    display: flex;
    margin-top: 66.7px;
    flex-direction: column;
    h2 {
      font-weight: 700;
      line-height: 31px;
      margin-bottom: 9px;
    }
  }
  &__form {
    margin-top: 63px;
    ion-button {
      margin-top: 48px;
      ion-spinner {
        margin-left: 10px;
      }
    }
  }
}
ion-modal#dob {
  --width: 290px;
  --height: 382px;
  --border-radius: 8px;
  ion-datetime {
    height: 382px;
    ion-buttons {
      justify-content: space-between;
      ion-button {
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
}
