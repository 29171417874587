.authorizationModal {
  --width: 100%;
  --height: max-content;
  --border-radius: 0px;
  align-items: flex-end;
  &::part(content) {
    padding-block: 35px 43px;
    padding-inline: var(--content-padding-horizontal);
  }
  &__header {
    display: flex;
    flex-direction: column;
  }
  &__title {
    font-size: 24px;
    line-height: 31px;
    margin-block: 23px 39px;
    h4 {
      font-weight: 700;
    }
  }
  &__close {
    --padding-end: 0px;
    --padding-start: 0px;
    margin: 0;
    width: max-content;
    height: max-content;
    svg {
      width: 20px;
      height: 20px;
    }
  }
  &__form {
    ion-col {
      --ion-grid-column-padding: 11px;
      flex-grow: 0;
      height: 50px;
      ion-item {
        width: 50px;
        height: 50px;
        &::part(native) {
          height: 100%;
        }
        ion-input {
          --placeholder-font-weight: 700;
          font-size: 24px;
          font-weight: 700;
          &.legacy-input {
            --padding-top: 0px;
            --padding-end: 0px;
            --padding-bottom: 0px;
            text-align: center;
            height: 100%;
          }
        }
      }
    }
  }
  &__submit {
    margin-top: 48px;
    &Text {
      gap: 10px;
      display: flex;
      align-items: center;
    }
  }
}
