.addMoney {
  &__content {
    --padding-end: var(--content-padding-horizontal);
    --padding-start: var(--content-padding-horizontal);
    display: flex;
    flex-direction: column;
    --background: conic-gradient(
        from 90deg at 18% 154px,
        rgba(var(--ion-background-color-rgb), 0) 57deg,
        rgba(var(--ion-color-primary-rgb), 0.8) 273deg
      ),
      conic-gradient(
        from 270deg at 82% 154px,
        rgba(var(--ion-color-primary-rgb), 0.8) 87deg,
        rgba(var(--ion-background-color-rgb), 0) 300deg
      );
    &::part(background) {
      background-size: 50% 100%, 50% 100%;
      background-repeat: no-repeat;
      background-position: 0px 0px, 100% 99%;
    }
  }
  &__header {
    display: flex;
    margin-block: 40px;
    &Button {
      --padding-end: 0px;
      --padding-start: 0px;
      margin: 0;
      width: max-content;
      height: max-content;
      svg {
        width: 20px;
        height: 20px;
        color: #fff;
      }
    }
    &Title {
      color: #fff;
      line-height: 31px;
      text-align: center;
      h1 {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 52px;
      }
    }
  }
  &__body {
    &Description {
      display: flex;
      line-height: 16px;
      text-align: center;
      justify-content: center;
      p {
        width: 180px;
        font-size: 12px;
      }
    }
  }
  &__accountDetails {
    gap: 18px;
    display: flex;
    margin-top: 38px;
    text-align: center;
    flex-direction: column;
    &Item {
      gap: 3px;
      display: flex;
      flex-direction: column;
      &Label {
        p {
          font-size: 10px;
          font-weight: 700;
          text-transform: uppercase;
        }
      }
      &Value {
        p {
          gap: 8px;
          display: flex;
          font-size: 20px;
          font-weight: 700;
          line-height: 26px;
          align-items: center;
          justify-content: center;
          svg {
            width: 20px;
            height: 20px;
          }
        }
        ion-button {
          --padding-start: 2px;
          --padding-end: 0px;
          margin: 0;
          height: max-content;
        }
      }
    }
  }
}
