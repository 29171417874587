.backButton {
  width: max-content;
  margin: 0;
  margin-inline: 0;
  height: max-content;
  &::part(native) {
    --padding-end: 0px;
    --padding-start: 0px;
  }
  svg {
    width: 22px;
    height: 19.3px;
  }
}
