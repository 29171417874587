.paymentMethods {
  &__header {
    height: 100px;
    padding-block: 68px 14px;
    padding-inline: var(--content-padding-horizontal);
    &Bg {
      left: 0;
      right: 0;
      bottom: 0;
      height: 200px;
      position: absolute;
      background: conic-gradient(
        from 270deg at 50% 50%,
        rgba(var(--ion-color-primary-rgb), 0.8),
        rgba(var(--ion-background-color-rgb), 0)
      );
    }
    & > .header-background {
      background: transparent;
      backdrop-filter: saturate(180%) blur(12px);
    }
    &Content {
      top: 54px;
      z-index: 2;
      left: 30px;
      right: 30px;
      position: absolute;
    }
    &Top {
      gap: 20px;
      display: flex;
      align-items: center;
    }
  }
  &__title {
    display: flex;
    ion-text {
      color: var(--ion-text-color);
      h1 {
        font-size: 22px;
        font-weight: 700;
      }
    }
  }
  &__content {
    --padding-top: 23px;
    --padding-bottom: 35px;
    ion-fab {
      bottom: 35px;
      right: var(--content-padding-horizontal);
      &-button {
        --transform: none;
        --border-radius: 50px;
        margin: 0;
        width: max-content;
        &::part(native) {
          width: 132px;
        }
        svg {
          width: 15px;
          height: 15px;
        }
        span {
          font-size: 14px;
          margin-left: 9px;
          font-weight: 700;
          line-height: 18px;
        }
      }
    }
  }
  &__skeleton {
    padding-top: 20px;
    --padding-end: var(--content-padding-horizontal);
    --padding-start: var(--content-padding-horizontal);
    &::part(native) {
      --inner-padding-end: 0px;
    }
    ion-label {
      margin-top: 0;
      ion-skeleton-text {
        margin-bottom: 15px;

        &:nth-child(2) {
          height: 50px;
        }
      }
    }
  }
  &__method {
    --padding-start: var(--content-padding-horizontal);
    ion-label {
      &.paymentMethods__methodLabel {
        display: flex;
        justify-content: space-between;
        h2 {
          font-size: 15px;
        }
      }
    }
    &Fields {
      gap: 4px;
      display: flex;
      margin-top: 4px;
      flex-direction: column;
      p {
        gap: 4px;
        display: flex;
      }
    }
  }
}
