.tradeRequestChat {
  &__fullScreenModal {
    &--btn {
      display: flex;
      justify-content: flex-end;
      margin: 20px;
    }
    &--close {
      height: max-content;
      --color: var(--ion-text-color);
      &::part(native) {
        padding: 0;
      }

      svg {
        width: 20px;
        height: 20px;
      }
    }

    &--image {
      max-height: 750px;
      margin: 0 20px;
    }
  }
  &__header {
    height: 260px;
    padding-block: 30px 14px;
    padding-inline: var(--content-padding-horizontal);
    &Bg {
      left: 0;
      right: 0;
      bottom: 0;
      height: 520px;
      position: absolute;
      background: conic-gradient(
        from 270deg at 50% 50%,
        rgba(var(--ion-color-primary-rgb), 0.8),
        rgba(var(--ion-background-color-rgb), 0)
      );
    }
    & > .header-background {
      background: transparent;
      backdrop-filter: saturate(180%) blur(12px);
    }
    &Content {
      top: 58px;
      z-index: 2;
      left: 30px;
      right: 30px;
      position: absolute;
    }
    &Top {
      display: flex;
      align-items: center;
      margin-bottom: 50px;
      justify-content: space-between;
    }
    &Btn {
      margin: 0;
      height: max-content;
      --color: var(--ion-text-color);
      &::part(native) {
        padding: 0;
      }
      &--close {
        svg {
          width: 20px;
          height: 20px;
        }
      }
      &--menu {
        svg {
          width: 3px;
          height: 16px;
        }
      }
    }
    &Row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:nth-child(2) {
        margin-bottom: 20px;
      }
      &:nth-child(3) {
        margin-bottom: 10px;
      }
    }
  }
  &__peer {
    gap: 10px;
    display: flex;
    align-items: center;
    &Avatar {
      width: 30px;
      height: 30px;
    }
    &Name {
      font-size: 15px;
      font-weight: 700;
    }
  }
  &__trade {
    &Status {
      font-size: 24px;
      font-weight: 700;
    }
    &Amount {
      font-size: 24px;
      font-weight: 700;
    }
    &Details {
      gap: 5px;
      display: flex;
      flex-direction: column;
      & > span {
        display: flex;
        ion-text {
          &:first-child {
            p {
              font-weight: 700;
            }
          }
        }
        p {
          font-size: 12px;
        }
      }
    }
  }
  &__content {
    --padding-bottom: 14px;
  }
  &__messages {
    gap: 20px;
    display: flex;
    flex-direction: column;
  }
  &__message {
    width: 80%;
    display: flex;
    min-height: 75px;
    flex-direction: column;
    &Content {
      display: flex;
      padding: 14px 20px;
      flex-direction: column;
      background-color: #cdd8df;
      @media (prefers-color-scheme: dark) {
        color: var(--ion-color-tertiary);
      }

      &Image {
        height: 200px;
        max-width: 200px;
        margin-bottom: 5px;
        object-fit: contain;
      }
    }
    &Pin {
      width: 10px;
      margin-left: 20px;
      border-top: 10px solid;
      border-color: #cdd8df;
      border-right: 10px solid transparent;
    }
    &Time {
      p {
        font-size: 10px;
      }
    }
    &--mine {
      align-self: flex-end;
      .tradeRequestChat__messageContent {
        color: var(--ion-color-light);
        background-color: var(--ion-color-primary);
      }
      .tradeRequestChat__messagePin {
        align-self: flex-end;
        margin-right: 20px;
        border-right: unset;
        border-top: 10px solid;
        border-color: var(--ion-color-primary);
        border-left: 10px solid transparent;
      }
      .tradeRequestChat__messageTime {
        align-self: flex-end;
      }
    }
  }
  &__action {
    font-size: 14px;
    &.link {
      &::part(native) {
        --padding-start: 1px;
      }
    }
  }
  &__footer {
    height: 91px;
    display: flex;
    align-items: center;
    mix-blend-mode: normal;
    backdrop-filter: blur(12px);
    padding-inline: var(--content-padding-horizontal);
    background-color: rgba(var(--ion-background-color-rgb), 0.2);
  }
  &__newMessage {
    width: 100%;
    display: flex;
    max-width: 768px;
    min-height: 50px;
    margin-inline: auto;
    align-items: center;
    border-radius: 25px;
    padding-inline: 19px 11px;
    justify-content: space-between;
    border: 1px solid var(--ion-color-tertiary);
    position: relative;
    &Attachment {
      margin: 0 10px 0 0;
      height: max-content;
      &::part(native) {
        padding: 0;
      }
      svg {
        width: 20px;
        height: 20px;
      }
    }
    &Input {
      flex: 1;
      resize: none;
      border: none;
      outline: none;
      min-height: 20px;
      max-height: 78px;
      overflow-y: auto;
      max-width: calc(100% - 60px);
      color: var(--ion-text-color);
      background-color: transparent;
      &:empty::before {
        content: attr(placeholder);
        color: var(--ion-color-medium);
      }
    }
    &Image {
      display: block;
      &Wrapper {
        right: 0;
        bottom: 55px;
        max-width: 100px;
        overflow: hidden;
        max-height: 100px;
        position: absolute;
        border-radius: 4px;
      }
    }

    &Close {
      --padding-end: 1px;
      --padding-start: 1px;
      --background: rgba(0, 0, 0, 0.3);
      top: 4px;
      margin: 0;
      right: 4px;
      height: 14px;
      position: absolute;
    }

    &Send {
      margin: 0;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      &::part(native) {
        padding: 0;
      }
      svg {
        width: 18px;
        height: 11px;
      }
    }
  }
  &__skeleton {
    display: flex;
    align-items: center;
    gap: 20px;
    --padding-end: var(--content-padding-horizontal);
    --padding-start: var(--content-padding-horizontal);
    &::part(native) {
      --inner-padding-end: 0px;
    }
    &Avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      align-self: flex-start;
    }
    ion-label {
      flex-grow: 1;
    }

    &--last {
      margin-top: 10px;
      ion-label {
        ion-skeleton-text {
          height: 30px;
          margin-bottom: 15px;
        }
      }
    }
  }
}
