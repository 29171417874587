.forgotPassword {
  max-width: 768px;
  margin-inline: auto;
  &__header {
    display: flex;
    margin-top: 66.7px;
    flex-direction: column;
    h2 {
      font-weight: 700;
      line-height: 31px;
      margin-bottom: 9px;
    }
  }
  &__form {
    margin-top: 43px;
    ion-button {
      margin-top: 48px;
      ion-spinner {
        margin-left: 10px;
      }
    }
  }
}
