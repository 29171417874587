.tabs {
  ion-tab-bar {
    --border: 0;
    --ion-safe-area-bottom: 2px;
    bottom: 0;
    width: 100%;
    position: fixed;
    --ion-tab-bar-background: rgba(var(--ion-background-color-rgb), 0.2);
    mix-blend-mode: normal;
    backdrop-filter: blur(12px);
    height: 104px;
    ion-tab-button {
      --color: var(--ion-text-color);
      .button-inner {
        gap: 15px;
      }
      &::part(native) {
        span {
          gap: 15px;
        }
      }
      svg {
        width: 32px;
        height: 28px;
        fill: none;
      }
      ion-label {
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
        margin: 12px 0 0 0;
      }
    }
  }
}
