.home {
  &__header {
    height: 279px;
    padding-block: 78px 14px;
    padding-inline: var(--content-padding-horizontal);
    &Bg {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      background: conic-gradient(
        from 180deg at 50% 44.8%,
        rgba(var(--ion-color-primary-rgb), 0.8) 0deg,
        rgba(var(--ion-background-color-rgb), 0) 290deg
      );
    }
    & > .header-background {
      background: transparent;
      backdrop-filter: saturate(180%) blur(12px);
    }
    &Content {
      top: 78px;
      z-index: 2;
      left: 30px;
      right: 30px;
      bottom: 14px;
      position: absolute;
    }
    &Top {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &Actions {
      width: 100%;
      display: flex;
    }
    &Action {
      flex: 1;
      display: flex;
      justify-content: center;
      &:first-child {
        ion-button {
          --color: #fff;
        }
      }
      ion-button {
        margin: 0;
        font-size: 15px;
        font-weight: 700;
        min-height: unset;
        height: max-content;
        letter-spacing: -0.06em;
        text-decoration-line: underline;
        &::part(native) {
          padding: 0 2px;
        }
      }
    }
  }
  &__title {
    gap: 4px;
    display: flex;
    ion-text {
      &:nth-child(2) {
        filter: invert(100%);
      }
    }
    h1 {
      font-weight: 700;
    }
  }
  &__menu {
    &Btn {
      --padding-top: 2px;
      --padding-end: 4px;
      --padding-bottom: 2px;
      --padding-start: 10px;
      margin: 0;
      min-height: unset;
      height: max-content;
      --color: var(--ion-text-color);
      svg {
        width: 3px;
        height: 16px;
      }
    }
    &Popover {
      --width: 160px;
    }
  }
  &__wallets {
    width: 100%;
    height: 104px;
    display: flex;
    align-items: stretch;
    margin-block: 14px 18px;
    border: 2px solid var(--ion-text-color);
  }
  &__wallet {
    flex: 1;
    gap: 7px;
    display: flex;
    flex-direction: column;
    padding: 11px 5px 7px 12px;
    ion-text {
      --color: var(--ion-text-color);
    }
    &:first-child {
      border-right: 1px solid var(--ion-text-color);
    }
    &:last-child {
      border-left: 1px solid var(--ion-text-color);
    }
    &Name {
      font-size: 10px;
      font-weight: 700;
      line-height: 13px;
      color: var(--color);
      letter-spacing: 0.15em;
    }
    &Balance {
      font-size: 32px;
      font-weight: 700;
      line-height: 42px;
      color: var(--color);
      letter-spacing: -0.06em;
      &Full {
        font-size: 13px;
        font-weight: 500;
        line-height: 17px;
        color: var(--color);
        letter-spacing: 0.12em;
      }
    }
  }
  &__content {
    --padding-bottom: 104px;
  }
  &__community {
    margin-top: 30px;
    padding-inline: var(--content-padding-horizontal);
    &Icon {
      width: 30px;
      height: 30px;
      color: #25d366;
      margin-bottom: 21px;
    }
    &Content {
      display: flex;
      justify-content: space-between;
      & > div {
        h2 {
          font-size: 20px;
          font-weight: 700;
          margin-bottom: 11px;
          line-height: 26.04px;
          letter-spacing: -0.06em;
        }
        p {
          font-size: 15px;
          font-weight: 500;
          line-height: 19.53px;
          letter-spacing: -0.06em;
        }
      }
      ion-button {
        --color: #25d366;
      }
    }
  }
  &__ongoingTrades,
  &__latestAds,
  &__transactions {
    &Header {
      display: flex;
      padding-top: 21px;
      align-items: center;
      justify-content: space-between;
      padding-inline: var(--content-padding-horizontal);
      ion-label {
        margin: 0;
        font-size: 20px;
        font-weight: 700;
        line-height: 26px;
      }
      .link {
        font-size: 12px;
      }
    }
  }
  &__transactions {
    margin-bottom: 52px;
  }
  ion-fab {
    bottom: 108px;
    &-button {
      --transform: none;
      --border-radius: 50px;
      margin: 0;
      width: max-content;
      &::part(native) {
        width: 132px;
      }
      svg {
        width: 15px;
        height: 15px;
      }
      span {
        font-size: 14px;
        margin-left: 9px;
        font-weight: 700;
        line-height: 18px;
      }
    }
  }
  &__actionsPopover {
    --width: 180px;
  }
}
