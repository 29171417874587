.tradeAd {
  padding-top: 20px;
  --padding-end: var(--content-padding-horizontal);
  --padding-start: var(--content-padding-horizontal);
  &::part(native) {
    --inner-padding-end: 0px;
  }
  ion-label {
    margin: 0;
  }
  ion-avatar {
    margin: 0 10px 0 0;
    align-self: flex-start;
  }

  &__rateInput {
    --padding-start: 0;
    --border-width: 2px;
    --border-color: none;
    --background: transparent;
    ion-input {
      font-size: 18px;
    }
  }

  &__header,
  &__content,
  &__footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__header {
    margin-bottom: 12px;
    .link {
      font-size: 15px;
      font-weight: 700;
      line-height: 20px;
    }

    .popoverBtn {
      --padding-top: 2px;
      --padding-end: 4px;
      --padding-bottom: 2px;
      --padding-start: 10px;
      margin: 0;
      min-height: unset;
      height: max-content;
      --color: var(--ion-text-color);
      svg {
        width: 3px;
        height: 16px;
      }
    }
  }
  &__seller {
    display: flex;
    flex-direction: column;
    &Avatar {
      width: 30px;
      height: 30px;
    }
    &Name {
      p {
        font-size: 15px;
        font-weight: 700;
        line-height: 20px;
      }
    }
    &Stats {
      p {
        font-size: 10px;
        font-weight: 500;
        line-height: 13px;
      }
    }
  }
  &__content {
    margin-bottom: 15px;
  }
  &__info {
    gap: 14px;
    display: flex;
    flex-direction: column;
  }
  &__available {
    p {
      font-size: 12px;
      line-height: 16px;
      span {
        &:first-child {
          font-weight: 700;
        }
      }
    }
  }
  &__limits {
    p {
      font-size: 10px;
      font-weight: 500;
      max-width: 180px;
      line-height: 15px;
      overflow-wrap: break-word;
      white-space: break-spaces;
      span {
        &:first-child {
          font-weight: 700;
        }
      }
    }
  }
  &__price {
    p {
      text-align: right;
      span {
        &:first-child {
          font-size: 24px;
          font-weight: 700;
          line-height: 31px;
        }
        &:last-child {
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;
        }
      }
    }
  }
  &__payments {
    gap: 10px;
    display: flex;
    &--more {
      padding-block: 4px;
      width: max-content;
      padding-inline: 10px;
    }
    &Badge {
      --padding-end: 6px;
      --padding-top: 0px;
      --padding-start: 6px;
      --padding-bottom: 0px;
      font-size: 9px;
      color: #758a99;
      font-weight: 700;
      line-height: 12px;
      border-radius: 0px;
      letter-spacing: 0.12em;
      background: rgba(174, 190, 201, 0.39);
    }
    &Trigger {
      --padding-end: 0px;
      --border-radius: 0px;
      --padding-start: 0px;
      height: max-content;
      margin: 0;
    }
    &Popover {
      --width: max-content;
      @media (prefers-color-scheme: dark) {
        --background: var(--ion-color-medium);
      }
    }
  }
  &__footer {
    margin-bottom: 17px;
  }
  &__currency {
    p {
      font-size: 10px;
      font-weight: 700;
      line-height: 13px;
    }
  }
  &--inactive {
    opacity: 0.6;
  }
}
