.addBankAccount {
  --width: 100%;
  --border-radius: 0px;
  --height: max-content;
  align-items: flex-end;
  &::part(content) {
    padding-block: 35px 43px;
    padding-inline: var(--content-padding-horizontal);
  }
  &__header {
    display: flex;
    flex-direction: column;
  }
  &__title {
    font-size: 24px;
    line-height: 31px;
    margin-block: 24px 43px;
    h4 {
      font-weight: 700;
    }
  }
  &__close {
    --padding-end: 0px;
    --padding-start: 0px;
    margin: 0;
    width: max-content;
    height: max-content;
    svg {
      width: 20px;
      height: 20px;
    }
  }
  &__form {
    ion-grid {
      display: flex;
      flex-direction: column;
      ion-row {
        margin-bottom: 22px;
        &:last-child {
          margin-top: 20px;
        }
      }
    }
  }

  &__select {
    border: 2px solid white;
    width: 100%;
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__accountName {
    gap: 10px;
    display: flex;
    margin-top: 13px;
    align-items: center;
    svg {
      width: 14px;
      height: 12px;
      color: #04c000;
    }
    span {
      font-size: 15px;
      font-weight: 500;
      line-height: 20px;
    }
  }
}
