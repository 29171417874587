.recentTransactions {
  margin-bottom: 52px;
  &__header {
    display: flex;
    padding-top: 21px;
    align-items: center;
    justify-content: space-between;
    padding-inline: var(--content-padding-horizontal);
    ion-label {
      margin: 0;
      font-size: 20px;
      font-weight: 700;
      line-height: 26px;
    }
    .link {
      font-size: 12px;
    }
  }
  &__empty {
    p {
      font-style: italic;
      padding-inline: var(--content-padding-horizontal);
    }
  }
}
